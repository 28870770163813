/* eslint-disable max-len */
/**
 * Listens for update events on ServiceWorkerRegistrations
 * @version 1.1
 */
// eslint-disable-next-line import/prefer-default-export
export class ServiceWorkerUpdateListener extends EventTarget {
  constructor() {
    super();
    this._registrations = [];
    this._eventListeners = [];
  }

  /**
   * Add a registration to start listening for update events
   * @param {ServiceWorkerRegistration} registration
   */
  addRegistration(registration) {
    // Abort if we are already listening for this registration
    if (this._registrations.includes(registration)) return;

    // Add the registration to the array of registrations
    this._registrations.push(registration);

    // Add a reference to the event listener and attach it to a registration so we can remove it when needed
    const addEventListenerForRegistration = (reg, target, type, listener) => {
      this._eventListeners.push({
        reg, target, type, listener,
      });
      target.addEventListener(type, listener);
    };

    // Convenience method to both dispatch the update event and call the relating method
    const dispatchUpdateStateChange = (state, serviceWorker, reg) => {
      const type = `update${state}`;
      const method = `on${type}`;
      const event = new CustomEvent(type, { detail: { serviceWorker, reg } });

      this.dispatchEvent(event);

      if (this[method] && typeof this[method] === 'function') this[method](event);
    };

    // Fire the `onupdatewaiting` event if there is already a Service Worker waiting
    if (registration.waiting) dispatchUpdateStateChange('waiting', registration.waiting, registration);

    // Listen for a new service worker at ServiceWorkerRegistration.installing
    addEventListenerForRegistration(registration, registration, 'updatefound', () => {
      // Abort if we have no active service worker already, that would mean that this is a new service worker and not an update
      if (!registration.active || !registration.installing) return;

      // Listen for state changes on the installing service worker
      addEventListenerForRegistration(registration, registration.installing, 'statechange', (statechangeevent) => {
        // The state should be installed, but double check to make sure
        if (statechangeevent.target.state !== 'installed') return;

        // Fire the `onupdatewaiting` event as we have moved from installing to the installed state
        dispatchUpdateStateChange('waiting', registration.waiting, registration);
      });

      // Fire the `onupdateinstalling` event
      dispatchUpdateStateChange('installing', registration.installing, registration);
    });

    // Listen for the document's associated ServiceWorkerRegistration to acquire a new active worker
    // eslint-disable-next-line no-unused-vars
    addEventListenerForRegistration(registration, navigator.serviceWorker, 'controllerchange', (controllerchangeevent) => {
      // Postpone the `onupdateready` event until the new active service worker is fully activated
      navigator.serviceWorker.ready.then((reg) => {
        // Fire the `onupdateready` event
        dispatchUpdateStateChange('ready', reg.active, reg);
      });
    });
  }

  /**
   * Remove a registration to stop listening for update events
   * @param {ServiceWorkerRegistration} registration
   */
  removeRegistration(registration) {
    // Abort if we don't have any registrations
    if (!this._registrations.length) return;

    // Remove all event listeners attached to a certain registration
    const removeEventListenersForRegistration = (reg) => {
      this._eventListeners = this._eventListeners.filter((eventListener) => {
        if (eventListener.reg === reg) {
          eventListener.target.removeEventListener(eventListener.type, eventListener.listener);
          return false;
        }
        return true;
      });
    };

    // Remove the registration from the array
    this._registrations = this._registrations.filter((current) => {
      if (current === registration) {
        removeEventListenersForRegistration(registration);
        return false;
      }
      return true;
    });
  }

  /**
   * Force the service worker to move from waiting to activating state.
   *
   * Note: This requires the service worker script file to listen for this message, for example:
   * self.addEventListener('message', event => { if (event.data === 'SKIP_WAITING') self.skipWaiting() });
   * @param {ServiceWorker} serviceWorker
   */
  // eslint-disable-next-line class-methods-use-this
  skipWaiting(serviceWorker) {
    serviceWorker.postMessage({ type: 'SKIP_WAITING' });
  }
}
