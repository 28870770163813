import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ServiceWorkerUpdateListener } from './serviceWorkerUpdateListener';
import store from './reducers';
import Routes from './Routes';
import './styles/app.scss';

import UpdateWaiting from './components/UpdateWaiting';

const App = () => {
  const [updateWaiting, setUpdateWaiting] = useState(false);
  const [registration, setRegistration] = useState(null);
  const [swListener, setSwListener] = useState(null);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      const initSW = async () => {
        const listener = new ServiceWorkerUpdateListener();
        setSwListener(listener);

        listener.onupdateinstalling = (installingEvent) => {
          console.log('SW installed', installingEvent);
        };
        listener.onupdatewaiting = (waitingEvent) => {
          console.log('New update waiting', waitingEvent);
          setUpdateWaiting(true);
        };
        listener.onupdateready = () => {
          console.log('Update ready event');
          window.location.reload();
        };

        const reg = await navigator.serviceWorker.getRegistration();
        if (reg) {
          listener.addRegistration(reg);
          setRegistration(reg);
        }

        return listener;
      };

      initSW().then((listener) => () => {
        if (listener && typeof listener.cleanup === 'function') {
          listener.cleanup();
        }
      });
    }
  }, []);

  const handleUpdate = () => {
    if (swListener && registration && registration.waiting) {
      swListener.skipWaiting(registration.waiting);
    }
  };

  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Routes />
        </Router>
      </Provider>
      <UpdateWaiting
        updateWaiting={updateWaiting}
        handleUpdate={handleUpdate}
      />
    </div>
  );
};

export default App;
