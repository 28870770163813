import { React } from 'react';
import styles from './modal-user-list.module.scss';

const ModalUserList = ({ user, setViewModal }) =>
  // console.log(user, 'dans modal');
  (
    <div className={styles.modal} onClick={() => setViewModal(false)}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h3>{user.profile.firstName} {user.profile.lastName}</h3>
          <span>{user.agency.name} - {user.fonctionEntreprise}</span>
        </div>
        <div className={styles.modalContenu}>
          <div className={styles.contenu}>
            <p>Telephone</p>
            <span>{user.phone}</span>
          </div>
          <div className={styles.contenu}>
            <p>Mail</p>
            <span>{user.email}</span>
          </div>
        </div>
      </div>
    </div>
  );
export default ModalUserList;
