import { combineReducers, createStore } from 'redux';
import authReducer from './authReducer';
import deliveryRoundReducer from './deliveryRoundReducer';
import notificationReducer from './notificationReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
  authReducer,
  deliveryRoundReducer,
  notificationReducer,
  userReducer,
});

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
