/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask'; // Import du package pour gérer les masques
import styles from './input-text.module.scss';

const InputText = ({
  name,
  control,
  rules,
  widthLabel = null,
  inline = false,
  label = null,
  placeholder,
  defaultValue = '',
  className,
  handleChange,
  maxlength,
  disabled,
  mask = null, // Nouvelle propriété
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    defaultValue={defaultValue}
    render={({
      field: {
        value = '',
        ref,
        onChange,
        onBlur,
      },
    }) => {
      function handlePressEnter(e) {
        if (e.key === 'Enter' && e.target) {
          e.target.blur();
        }
      }

      const inputProps = {};

      if (maxlength) {
        inputProps.maxLength = maxlength;
      }

      return (
        <div
          onKeyUp={(e) => handlePressEnter(e)}
          className={
          inline ? `${className ? styles[className] : ''} ${styles['container-input']} ${styles.inline}`
            : `${className ? styles[className] : ''} ${styles['container-input']}`
        }
        >
          {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
          {mask ? (
            <InputMask
              mask={mask} // Application du masque ici
              disabled={disabled}
              value={value}
              onBlur={onBlur}
              onChange={(e) => {
                if (handleChange) handleChange(e.target.value);
                onChange(e.target.value);
              }}
            >
              {(inputProps) => (
                <input
                  {...inputProps}
                  ref={ref}
                  className={styles.input}
                  placeholder={placeholder}
                />
              )}
            </InputMask>
          ) : (
            <input
              ref={ref}
              disabled={disabled}
              value={value}
              onBlur={onBlur}
              onChange={(e) => {
                if (handleChange) handleChange(e.target.value);
                onChange(e.target.value);
              }}
              className={styles.input}
              placeholder={placeholder}
              {...inputProps}
            />
          )}
        </div>
      );
    }}
  />
);

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  label: PropTypes.string,
  inline: PropTypes.bool,
  widthLabel: PropTypes.string,
  placeholder: PropTypes.string,
  mask: PropTypes.string, // PropTypes pour le masque
};

export default InputText;
