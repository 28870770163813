import { USERS_GET, USER_LOADING } from '../actions/types';

const DEFAULT_STATE = {
  users: [],
  isLoading: [],
  error: null,
};

const userReducer = (state = DEFAULT_STATE, action) => {
  let updatedState = {};
  switch (action.type) {
    case USERS_GET:
      updatedState = {
        ...state,
        users: action.payload,
        isLoading: state.isLoading?.filter((d) => d !== USERS_GET),
        error: null,
      };
      break;
    case USER_LOADING:
      updatedState = {
        ...state,
        error: null,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    default:
      updatedState = state;
  }
  return updatedState;
};

export default userReducer;
