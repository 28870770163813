import { getData } from './index';
import { USERS_GET, USER_LOADING } from './types';

const getContact = async (dispatch, params = {}) => {
  const queryString = new URLSearchParams(params).toString();
  const url = `/users/mobile/contact${queryString ? `?${queryString}` : ''}`;
  dispatch({
    type: USER_LOADING,
    payload: USERS_GET,
  });
  const response = await getData(USER_LOADING, url, dispatch, true);
  if (response.data) {
    dispatch({
      type: USERS_GET,
      payload: response.data,
    });
  }
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getContact,
};
