/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BsArrowLeftShort } from 'react-icons/bs';
import { CgUserList } from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';
import styles from './contact.module.scss';
import BottomNav from '../../components/BottomNav';
import NotificationsBell from '../../components/NotificationsBell';
import { getContact } from '../../actions/user';
import ModalUserList from '../../components/ModalUserList';

const Contact = () => {
  const [viewModal, setViewModal] = useState(false);
  const dispatch = useDispatch();
  const { userReducer } = useSelector((store) => store);
  const [userModal, setUserModal] = useState();

  const { users } = userReducer;

  const openModal = (userInModal) => {
    setViewModal(true);
    setUserModal(userInModal);
  };

  useEffect(() => {
    getContact(dispatch);
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.contact}>
        <div className={styles.header}>
          <NavLink to="/home">
            <BsArrowLeftShort size={40} />
          </NavLink>
          <div>
            <h1>Contact</h1>
          </div>
          <div className={styles.notifications}>
            <NotificationsBell color="#05004E" />
          </div>
        </div>
        <div>
          <h2>Mon agence</h2>
          <div>
            <ul>
              {users && users.map((user) => (
                <li key={user._id}>
                  <div className={styles.insideListe} onClick={() => openModal(user)}>
                    <div className={styles.leftsection}>
                      <CgUserList size={40} />
                    </div>
                    <div className={styles.rightSection}>
                      <div>
                        <span className={styles.name}>{user.profile.firstName} {user.profile.lastName}</span>
                      </div>
                      <div>
                        <span className={styles.infos}>{user.agency.name} - {user.fonctionEntreprise}</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.borderTrait} />
                  {viewModal && <ModalUserList user={userModal} setViewModal={setViewModal} />}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.bottom}>
          <BottomNav />
        </div>
      </div>
    </div>
  );
};

export default Contact;
