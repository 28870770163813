import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileAction } from './actions/auth';

import Layout from './components/Layout';
import Loader from './components/Loader';
import SignIn from './pages/signin';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import Home from './pages/home';
import Timetable from './pages/timetable';
import Account from './pages/account';
import Notifications from './pages/notifications';
import Profile from './pages/account/profile';
import License from './pages/account/license';
import Edit from './pages/account/edit/edit';
import Contact from './pages/contact';

const Redirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      getProfileAction(dispatch).catch(() => {
        navigate('/');
      });
    } else navigate('/');
  }, []);

  return <Loader />;
};

const AppRoutes = () => {
  const authReducer = useSelector((state) => state.authReducer);
  const { user } = authReducer;

  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      {user && (
        <Route
          path="*"
          element={(
            <Layout key="app">
              <Routes>
                <Route path="home" element={<Home />} />
                <Route path="notifications" element={<Notifications />} />
                <Route path="profile" element={<Account />}>
                  <Route index element={<Profile />} />
                  <Route path="edit" element={<Edit />} />
                  <Route path="license" element={<License />} />
                </Route>
                <Route path="timetable" element={<Timetable />} />
                <Route path="contact" element={<Contact />} />
              </Routes>
            </Layout>
          )}
        />
      )}
      <Route path="*" element={<Redirect />} />
    </Routes>
  );
};

export default AppRoutes;
